import './About2.css'

import icon1 from './icon1.png'
import icon2 from './icon2.png'
import icon3 from './icon3.png'

import Footer from '../footer/Footer'

function About2() {
  return (
      <div className="footer__about2__container">
        <section className="about2" >
            <div className="about2__section">
                <img src={icon1} alt="Reliable icon" className="about2__section__icon" />
                <h4 className="about2__section__title">
                    Reliable
                </h4>
                <p className="about2__section__text">
                    We aim to provide safe, reliable, and<br/>
                    transparent FTSO services using quality<br/>
                    infrastructure.
                </p>
            </div>
            <div className="about2__section">
                <img src={icon2} alt="Reliable icon" className="about2__section__icon" />
                <h4 className="about2__section__title">
                    Secure
                </h4>
                <p className="about2__section__text">
                    Our nodes are located in secure data<br/>
                    centers in the nothern part of the planet.
                </p>
            </div>
            <div className="about2__section">
                <img src={icon3} alt="Reliable icon" className="about2__section__icon" />
                <h4 className="about2__section__title">
                    Support
                </h4>
                <p className="about2__section__text">
                    Find us on twitter <a href="https://twitter.com/scandinodesFTSO" target="_blank" rel="noreferrer">@scandinodesftso</a>
                </p>
            </div>
            
        </section>
        <Footer />
    </div>
  );
}

export default About2