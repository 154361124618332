import './PricePopup.css'

function PricePopup(props) {
  return (
    <div className={"popup popup__" + props.index } style={props.pos}>
        <div class="popup__price">
            <p>{ props.denom }</p>
            <p>{ props.price }</p>
        </div>
        <div class="popup__line"></div>
        <div class="popup__dot"></div>
    </div>
  )
}

export default PricePopup