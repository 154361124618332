import './Footer.css'

import twitter from './twitter.svg'
import scandinodes from './scandinodes.svg'

function Footer() {
  return (
    <footer className="footer" >
        <div className="footer__container">
            <p className="footer_text">
                &copy; {new Date().getFullYear()} ScandiNodes. All Rights Reserved, ScandiNodes
            </p>
            <a href="https://twitter.com/scandinodesFTSO" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter Logo" /></a>
            <img src={scandinodes} alt="Scandinodes Logo" className="footer__logo" />
        </div>
    </footer>
  );
}

export default Footer