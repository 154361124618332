import React, { useEffect, useState } from "react"
import axios from 'axios'

import PricePopup from './pricePopup/PricePopup'

import map from './map.svg'

import './Home.css';

function Home() {
  const [appState, setAppState] = useState({ ready: false, objects: [] })

  const delay = ms => new Promise(res => setTimeout(res, ms))

  function randomInt([min, max]) {
    return min + Math.floor(Math.random() * (max - min))
  }

  function findPrice(coin, prices) {
    let result

    prices.forEach(e => { if(e.symbol === coin + "USDT") result = parseFloat(e.price) })

    return result
  }

  useEffect(() => {

    async function createPriceTag(){

      const range = [
        { bottom: window.innerWidth > 450 ? [50, 70] : [55, 70], left: window.innerWidth > 450 ? [20, 40] : [25, 45] },
        { bottom: window.innerWidth > 450 ? [40, 60] : [35, 55], left:  window.innerWidth > 450 ? [70, 90] : [55, 65] },
        { bottom: [20, 40], left: [40, 60] },
      ]

      while(true) {
        const prices = (await axios.get('https://api.binance.com/api/v3/ticker/price')).data

        await delay(500)
        setAppState({ ready: true, objects: [
          { denom: "BTC", price: findPrice('BTC', prices), bottom: randomInt(range[0].bottom) + "%", left: `calc(${randomInt(range[0].left)}% - 100px)` },
          { denom: "XRP", price: findPrice('XRP', prices), bottom: randomInt(range[1].bottom) + "%", left: `calc(${randomInt(range[1].left)}% - 100px)` },
          { denom: "LUNA", price: findPrice('LUNA', prices), bottom: randomInt(range[2].bottom) + "%", left: `calc(${randomInt(range[2].left)}% - 100px)`},
        ]})
        await delay(8000)
        setAppState({ ready: false, objects: [] })

        await delay(500)
        setAppState({ ready: true, objects: [
          { denom: "ADA", price: findPrice('ADA', prices), bottom: randomInt(range[0].bottom) + "%", left: `calc(${randomInt(range[0].left)}% - 100px)` },
          { denom: "ETH", price: findPrice('ETH', prices), bottom: randomInt(range[1].bottom) + "%", left: `calc(${randomInt(range[1].left)}% - 100px)` },
          { denom: "LTC", price: findPrice('LTC', prices), bottom: randomInt(range[2].bottom) + "%", left: `calc(${randomInt(range[2].left)}% - 100px)` },
        ]})
        await delay(8000)
        setAppState({ ready: false, objects: [] })

        await delay(500)
        setAppState({ ready: true, objects: [
          { denom: "DOGE", price: findPrice('DOGE', prices), bottom: randomInt(range[0].bottom) + "%", left: `calc(${randomInt(range[0].left)}% - 100px)` },
          { denom: "XLM", price: findPrice('XLM', prices), bottom: randomInt(range[1].bottom) + "%", left: `calc(${randomInt(range[1].left)}% - 100px)` },
          { denom: "ALGO", price: findPrice('ALGO', prices), bottom: randomInt(range[2].bottom) + "%", left: `calc(${randomInt(range[2].left)}% - 100px)` },
        ]})
        await delay(8000)
        setAppState({ ready: false, objects: [] })

        await delay(500)
        setAppState({ ready: true, objects: [
          { denom: "BCH", price: findPrice('BCH', prices), bottom: randomInt(range[0].bottom) + "%", left: `calc(${randomInt(range[0].left)}% - 100px)` },
          { denom: "FIL", price: findPrice('FIL', prices), bottom: randomInt(range[1].bottom) + "%", left: `calc(${randomInt(range[1].left)}% - 100px)` },
          { denom: "DGB", price: findPrice('DGB', prices), bottom: randomInt(range[2].bottom) + "%", left: `calc(${randomInt(range[2].left)}% - 100px)` },
        ]})
        await delay(8000)
        setAppState({ ready: false, objects: [] })
      }
    }

    createPriceTag()

  }, [setAppState])

  function findPos(obj) {
      let curtop = 0
      if (obj.offsetParent) {
          do {
              curtop += obj.offsetTop
          } while (obj === obj.offsetParent)
        return [curtop]
      }
  }

  return (
    <section className="home">
        <div className="home__map__container">
            {
              appState.ready ? 
                appState.objects.map((e, i) => <PricePopup denom={e.denom + "/USD"} price={e.price} pos={{ bottom: e.bottom, left: e.left}} key={i} index={i} />) :
                <></>
            }
            <img src={map} alt="world map" className="home__map"/>
        </div>
        <h2 className="home__title">We're a Flare time series Oracle <br /> signal provider</h2>
        <div className="home__button__container">
            <button onClick={() => window.scrollTo({ top: findPos(document.getElementById("about")) - document.getElementById('header').offsetHeight, behavior: 'smooth'}) } className="home__button" >READ MORE</button>
        </div>
    </section>
  );
}

export default Home