import './About.css'

function About() {
  return (
    <section className="about" id="about">
        <h2 className="about__title">
            FTSO Signal <br/>
            Provider <br/>
            And Agent
        </h2>
        <div className="about__text__container">
          <p className="about__text">
            We are proud to be a part of <a href="https://flare.xyz/" target="_blank" rel="noreferrer">Flare Networks</a>. We have a responsibility and take our role as a signal provider and agent very serious.
          </p>
          <p className="about__text">
            We do not only provide data estimates. We provide good estimates, for maximum returns.
          </p>
          <p className="about__text">
            Delegate your votes to us and we will provide honest votes. Maximizing your reward.
          </p>
          <h3 className="about__subtitle">
            Who are we?
          </h3>
          <p className="about__text">
            We are crypto enthusiasts, programmers and entrepreneurs.<br /> We deliver a quality-assured product in close collaboration with Flare Networks.<br /> We never stand still and develop our service according to the blockchain's development and your needs.
          </p>
          <p className="about__text about__text__bottom">
            Thank you for being a part of ScandiNodes FTSO!
          </p>
        </div>
        <div className="about__uptime__container">
          <h1>99%</h1>
          <h3>UPTIME SINCE 202109</h3>
        </div>
    </section>
  );
}

export default About