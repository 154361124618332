import Header from './components/header/Header'
import Home from './components/home/Home'
import About from './components/about/About'
import About2 from './components/about2/About2'

import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <About2 />
    </div>
  );
}

export default App;
