import scandinodes from './scandinodes.svg'
import icon from './icon.svg'

import './Header.css'

function Header() {
  return (
    <header className="header" id="header">
        <div className="header__container">
            <img src={scandinodes} alt="Scandinodes" className="heaeder__logo"/>
            <img src={icon} alt="Scandinodes" className="heaeder__icon"/>
            <a className="header__text" href="https://app.scandinodes.com/" target="_blank" rel="noreferrer" >Web App</a>
        </div>
    </header>
  );
}

export default Header